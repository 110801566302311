import React, { Component } from "react";
import { Menu } from "./components/Menu"
import {EGG, Modal, Header, DailyReward, DailyCypher, DailyCombo, Balance, Energy, CHOOSE_EGG} from "./components/EGG/egg_page"
import TASKS from "./components/TASKS/tasks_page"
import { CongratsCoins } from "./components/congrats/congrats_component"
import UPGRADE from "./components/UPGRADE/upgrade_page"
import FRIENDS from "./components/FRIENDS/friends_page"
import START from "./components/START/start_page"
import LOADING from "./components/LOADING/loading_page"
import CypherBlock from "./components/CYPHER/cypher_component"
import { BOOST_SCREEN } from "./components/BOOST/boost_component"
import { DailyCountdown } from './components/CountDowns';
import { preloadImages } from './imagePreloader'
import { preloadImagesUPGRADE } from './components/UPGRADE/loader_images'
import "./telegramContext"
import { TG } from "./telegramContext"
import './css/Placeholder.css'
import "./css/Header.css"
import "./css/Menu.css"
import "./index.css"
import axios from 'axios';
import SETTINGS_DATA from "./components/settings/settings_component";
import PRIVACY_POLICY from "./components/settings/privacy_policy";
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();


export const api = axios.create({
  baseURL: "https://eggame.ru"
  // withCredentials: true
  // baseURL: "https://87.228.25.233:3000"
});


class App extends Component {
    state = {
      id: 0,
      name: "Unknown",
      balance: 0,
      egg: 0,
      clickValue: 1,
      profitPerHour: 0,
      level: 1,
      screenview: "LOADING",
      additionalView: "",
      upgrades: {},
      DailyCombo: [],
      DailyRewardDay: 0,
      energyValue: 0,
      EnergyPerDelay: 1,
      DelayEnergy: 1000,
      EnergyLimit: 500,
      ComboСurrentReward: 300000,
      tasks: [{"id":"task_x", "status":"done"}],
      friends: [],
      dailyRewardModal: false,
      startRewardModal: false,
      is_premium: false,
      last_daily_bonus_collection_timestamp: 0,
      offline_reward: 0,
      language_code: 'ru',
      sync_earnings: 0,
      sync: true,
      gratsView: false,
      cypherAvailableView: true,
      loadedImages: [],
      all_upgrades: {},
      isAuthenticated: false
    };
    // state = {
    //   energyValue: 0,
    //   all_tasks: []
    // }
    photoToUpgrades = []

    energyAdd = () => {
      if (this.state.energyValue < this.state.EnergyLimit) {
        var toChangeValue = this.state.energyValue + this.state.EnergyPerDelay
        if (toChangeValue > this.state.EnergyLimit) {
          toChangeValue = this.state.EnergyLimit
        }
        this.setState({ energyValue: toChangeValue });
      }
    }

    closeCongrats = () => {
      this.setState({
        gratsView: false
      })
    }

    openCongrats = () => {
      this.setState({
        gratsView: true
      })
    }

    start_func = (Data: any) => {
      console.log(Data)
      var date = new Date();
      var now = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0));
      var tomorrow = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59));

      var userData = Data.user;
      var userStateData = Data.state;

      var CanCypher = true;
      if (Number(now.getTime()) / 1000 < userData.last_daily_cypher_completed_timestamp) {
        CanCypher = false;
      }

      var CanCollect = true;
      if (Number(now.getTime()) / 1000 <= userData.last_daily_bonus_collection_timestamp && userData.last_daily_bonus_collection_timestamp < Number(tomorrow.getTime()) / 1000) {
        CanCollect = false;
      }

      if (userData.egg === 0) {
        this.setState({
          startRewardModal: true
        });
      }

      if (CanCollect) {
        this.setState({
          dailyRewardModal: true
        });
      } else {
        this.setState({
          dailyRewardModal: false
        });
      }

      if (userData) {
        this.setState({
          id: userData.id,
          name: userData.name,
          balance: userData.balance,
          egg: userData.egg,
          level: userData.level,
          clickValue: userData.click_value,
          profitPerHour: userData.profit_per_hour,
          upgrades: userData.upgrades_lvls,
          DailyCombo: userData.daily_combo_collected,
          DailyRewardDay: userData.daily_bonus_streak_days,
          ComboСurrentReward: userStateData.current_combo_reward,
          CrazyEnergyUsed: userData.crazy_energy_purchased_today_qty,
          energyValue: userData.energy_value,
          EnergyLimit: userData.energy_limit,
          EnergyPerDelay: userData.energy_recovery_per_delay,
          DelayEnergy: userData.DelayEnergy,
          is_premium: userData.is_premium,
          last_daily_bonus_collection_timestamp: userData.last_daily_bonus_collection_timestamp,
          tasks: userData.tasks_statuses,
          offline_reward: userStateData.offline_reward,
          all_tasks: userStateData.tasks,
          all_upgrades: userStateData.upgrades,
          BybitUID: userData.bybit_uid,
          BybitStatus: userData.bybit_status,
          cypherAvailableView: CanCypher,
          language_code: userData.language_code,
          nextCrazyEnergyTimestamp: userData.next_crazy_energy_available_timestamp,
          friends: userData.referrals_lines,
          photoToUpgrades: [],
          loadedImages: [],
          game_stage: userData.stage,
          sync: true
        });
    }

    preloadImages().then(d => {
      // this.setState({ loadedImages: d});
        preloadImagesUPGRADE(userData.stage).then(dy => {
          this.setState({ screenview: "START", photoToUpgrades: dy});
        }).catch(e => {console.log(e);});
      }).catch(e => {
        console.log(e);
      });
    };

    syncSession = async () => {
      if (!this.state.sync) {
        await api.post('/api/user/syncSession', {
            "balance_increase": this.state.sync_earnings,
            "energy_value": this.state.energyValue
        }).then(res => {
          this.setState({
            sync: true,
            sync_earnings: 0,
          })
        }).catch(err => {
          if (err.status === 401) {
            TG.WebApp.close()
          }
          console.log(err)
        })
      }
    }

    screenWidth = window.screen.width
    screenHeight = window.screen.height

    componentDidMount = async () => {
        TG.WebApp.ready()
        const initData = TG.WebApp.initData
        TG.WebApp.disableVerticalSwipes()

        const encodedInitData = encodeURIComponent(initData);

        await api.put(`/api/user/initSession?init_data=${encodedInitData}`, {
            "ref_id": TG.WebApp.initDataUnsafe.start_param
        })
        .then(response => {
          // cookies.set('access_token', encodeURIComponent(response.data.access_token), {path: '/'});
          // document.cookie = `access_token=${encodeURIComponent(response.data.access_token)}; path=/;`;
          api.defaults.headers.common['Authorization'] = `Bearer ${String(response.data.access_token)}`;
          this.setState({
            isAuthenticated:true
          })
          this.start_func(response.data);
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isAuthenticated:false
          })
        });

        setInterval(() => {
            this.energyAdd()
          }, 1000);
        // SYNC
        setInterval(() => {
            this.syncSession()
          }, 1000);
      }


    onEGGidChange = (egg_id: number) => {
      api.put('/api/user/update', {
        "egg":egg_id
      }).then(res => {
        this.setState({
          egg: egg_id
        })
      }).catch(err => {
        this.setState({
          egg: egg_id
        })
      })
    }

    updateView = (view: string) => {
      if (view === "EGG") {
        this.setState({
          screenview: view,
          additionalView: ''
        })
      } else if (view === "FRIENDS") {
        api.get(`/api/user/get`).then(res => {
          this.setState({
            friends: res.data.referrals_lines,
            screenview: view
          })
        }).catch(err => {
          console.log(err)
          this.setState({
            screenview: view
          })
        })
      } else {
        this.setState({
          screenview: view
        })
      }
    }

    updateUserLang = async (lang: string) => {
      api.put('/api/user/update', {"language_code": lang}).then(res => {
        this.setState({
          "language_code": lang,
        })
        TG.WebApp.close()
      })
    }

    updateAdditionalView = (view: string) => {
      this.setState({
        additionalView: view
       })
    }

    updateAdditionalCypherView = () => {
      if (this.state.additionalView === "CYPHER") {
        this.setState({
          additionalView: ""
         })
      } else {
        this.setState({
          additionalView: "CYPHER"
         })
      }
    }

    addTaskDone = (balance:number, tasks_statuses:any) => {
      this.setState({
        tasks: tasks_statuses,
        balance: balance,
        sync_earnings: 0
      })
    }

    addBybit = (bybit_id:string, res_data: any) => {
      this.setState({
        tasks: res_data.tasks_statuses,
        BybitUID: bybit_id,
        sync_earnings: 0
      })
    }

    getOfflineReward = () => {
      this.setState({
        offline_reward: 0
       })
    }

    getStartReward = () => {
      this.setState({
        startRewardModal: false
       })
    }

    getDailyReward = async () => {
      api.post('/api/task/verify', {
          "block_id":"block_1",
          "task_id":"task_dailyreward"
        }).then(res => {
          this.setState({
            dailyRewardModal: false,
            balance: res.data.current_balance,
            last_daily_bonus_collection_timestamp: res.data.last_daily_bonus_collection_timestamp,
            DailyRewardDay: res.data.current_daily_bonus_streak_days,
            gratsView: true,
            sync_earnings: 0
          })
        }).catch(err => {
          console.log(err)
          this.setState({dailyRewardModal: false})
      })
   }

    clickToAddBalance = () => {
        this.setState({
          sync: false,
          sync_earnings: this.state.sync_earnings+this.state.clickValue,
          balance: this.state.balance+this.state.clickValue,
          energyValue: this.state.energyValue - this.state.clickValue
        })
    }

    buyBoost = async (boostName:string) => {
      if (boostName === "crazy_energy") {
        if (this.state.EnergyLimit === this.state.energyValue) {
          return
        }
      }
      if (!this.state.sync) {
        this.syncSession()
      }
      api.post('/api/store/boost', {
          "boost_type": boostName
      }).then(res => {
        if (boostName === "crazy_energy") {
          this.setState(
            {
              nextCrazyEnergyTimestamp: res.data.next_crazy_energy_available_timestamp,
              CrazyEnergyUsed: res.data.crazy_energy_purchased_today_qty,
              balance: res.data.balance,
              clickValue: res.data.click_value,
              energyValue: res.data.energy_limit,
              EnergyLimit: res.data.energy_limit,
              EnergyPerDelay: res.data.energy_recovery_per_delay,
              sync_earnings: 0
            })
        } else {
          this.setState(
            {
              balance: res.data.balance,
              clickValue: res.data.click_value,
              EnergyLimit: res.data.energy_limit,
              EnergyPerDelay: res.data.energy_recovery_per_delay,
              sync_earnings: 0
            }
          )
        }
      })
    }

    buyUpgrade = (res: any) => {
      this.setState({
        balance: res.data.balance,
        upgrades: res.data.upgrades_lvls,
        DailyCombo: res.data.daily_combo_collected,
        profitPerHour: res.data.profit_per_hour,
        sync_earnings: 0
        })
    }

    doneCypher = (res: any) => {
      if (res === "error") {
        this.setState({
          cypherAvailableView: false,
          additionalView: ""
        })
      } else {
        this.setState({
          balance: res.current_balance,
          cypherAvailableView: false,
          additionalView: "",
          sync_earnings: 0
        })
      }
      
    }

    updateUserName = (new_name:string) => {
      this.setState({
        name:new_name
      })
    }

    deleteUser = (res_data:any) => {
      this.updateView("START")
      TG.WebApp.close()

      // this.setState({
      //   id: userData.id,
      //   name: userData.name,
      //   balance: userData.balance,
      //   egg: userData.egg,
      //   level: userData.level,
      //   clickValue: userData.click_value,
      //   profitPerHour: userData.profit_per_hour,
      //   upgrades: userData.upgrades_lvls,
      //   DailyCombo: userData.daily_combo_collected,
      //   DailyRewardDay: userData.daily_bonus_streak_days,
      //   ComboСurrentReward: userStateData.current_combo_reward,
      //   CrazyEnergyUsed: userData.crazy_energy_purchased_today_qty,
      //   energyValue: userData.energy_value,
      //   EnergyLimit: userData.energy_limit,
      //   EnergyPerDelay: userData.energy_recovery_per_delay,
      //   DelayEnergy: userData.DelayEnergy,
      //   friends: userData.upline_referrers,
      //   is_premium: userData.is_premium,
      //   last_daily_bonus_collection_timestamp: userData.last_daily_bonus_collection_timestamp,
      //   tasks: userData.tasks_statuses,
      //   offline_reward: userStateData.offline_reward,
      //   all_tasks: userStateData.tasks,
      //   BybitUID: userData.bybit_uid,
      //   BybitStatus: userData.bybit_status,
      //   cypherAvailableView: CanCypher,
      //   language_code: userData.language_code,
      //   sync: true,
      // })
    }


    render() {
      if (this.state.isAuthenticated) {
        switch (this.state.screenview) {

          case 'PRIVACY_POLICY':
            return (
              <main>
                <>
                  <PRIVACY_POLICY userData={this.state} updateView={this.updateView}/>
                </>
                <Menu updateView={this.updateView} activeScreen={''}/>
              </main>
            )

          case 'SETTINGS':
            return (
              <main>
                <>
                  <SETTINGS_DATA userData={this.state} updateUserLang={this.updateUserLang} updateView={this.updateView} updateUserName={this.updateUserName} deleteUser={this.deleteUser}/>
                </>
                <Menu updateView={this.updateView} activeScreen={''}/>
              </main>
            )

          case 'LOADING':
            return (
              <main>
                <>
                  <LOADING userData={this.state}/>
                </>
              </main>
            )

          case 'START':
            return (
              <main>
                <>
                  <START updateView={this.updateView} userData={this.state}/>
                </>
              </main>
            )

          case 'EGG':
            if (this.state.egg !== 0) {
              return (
                <>
                <main>
                  <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} userData={this.state} updateView={this.updateView}/>
                  <div className="daily_block">
                    <DailyReward userData={this.state} getDailyReward={this.getDailyReward}/>
                    <DailyCypher action={() => this.updateAdditionalCypherView()} availableView={this.state.cypherAvailableView} userData={this.state}/>
                    <DailyCombo updateView={this.updateView} countdown={<DailyCountdown />} userData={this.state}/>
                  </div>
                  <div className="egg_balance__block">
                    {(this.state.additionalView === 'CYPHER') ? 
                      <CypherBlock userData={this.state} doneCypher={this.doneCypher} openCongrats={this.openCongrats}/>
                      :
                      <Balance value={this.state.balance}/>
                  }
                  </div>
                  <EGG 
                    egg={this.state.egg}
                    energyEnough={(this.state.energyValue >= this.state.clickValue) ? true : false}
                    canIClickPlease={true}
                    sleep={false}
                    funMode={false}
                    userData={this.state}
                    Mode={this.state.additionalView}
                    clickValue={this.state.clickValue}
                    cooldown={0}
                    handleClick={this.clickToAddBalance}
                  />
                  <div className="above_menu__block">
                      <Energy
                        value={this.state.energyValue}
                        limit={this.state.EnergyLimit}
                      />
                      <BOOST_SCREEN userData={this.state} updateUser={this.buyBoost} dailyCounter={<DailyCountdown />}/>
                  </div>
                  <Menu updateView={this.updateView} activeScreen={'EGG'}/>

                  <Modal 
                    type="OfflineEarnings"
                    visible={(this.state.offline_reward > 0) ? (true) : (false)}
                    userData={this.state}
                    onClose={() => this.getOfflineReward()}
                    updateUser={() => this.getOfflineReward()}
                  />

                  <Modal 
                    type="StartReward"
                    visible={this.state.startRewardModal}
                    userData={this.state}
                    onClose={() => this.getStartReward()}
                    updateUser={() => this.getStartReward()}
                  />
                  
                  {(this.state.startRewardModal) ? <></> : <Modal
                    type="DailyReward"
                    visible={this.state.dailyRewardModal}
                    userData={this.state}
                    onClose={() => this.getDailyReward()}
                    updateUser={() => this.getDailyReward()}
                  />}
                  <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
                </main>
                </>
                )
          } else {
            return (
              <main className="start">
                <CHOOSE_EGG onEGGidChange={this.onEGGidChange} userData={this.state}/>
              </main>
            )};

        case 'BATTLES':
          return (
            <main>
              <>
                <div className="placeholder__block">
                  <div className="placeholder__text">
                    {
                      (this.state.language_code === "ru") ? 
                        ("Скоро откроется")
                      :
                        ("Will be launched soon")
                    }
                  </div>
                </div>
                <Menu updateView={this.updateView} activeScreen={'BATTLES'}/>
              </>
            </main>
          )

        case 'FRIENDS':
          return (
            <main>
              <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} userData={this.state} updateView={this.updateView}/>
              <FRIENDS userData={this.state} updateView={this.updateView}/>
              <Menu updateView={this.updateView} activeScreen={'FRIENDS'}/>
            </main>
          )

        case 'TASKS':
          return (
            <main>
              <>
                <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} userData={this.state} updateView={this.updateView}/>
                <TASKS userData={this.state} addTaskDone={this.addTaskDone} addBybit={this.addBybit} openCongrats={this.openCongrats}/>
                <Menu updateView={this.updateView} activeScreen={'TASKS'}/>
              </>
              <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
            </main>
          )

        case 'UPGRADE':
          return (
            <main>
              <>
                <Header name={this.state.name} level={this.state.level} profit={this.state.profitPerHour} userData={this.state} updateView={this.updateView}/>
                <UPGRADE balance={this.state.balance} userData={this.state} buyUpgrade={this.buyUpgrade} syncSession={this.syncSession} openCongrats={this.openCongrats} photoToUpgrades={this.photoToUpgrades}/>
                <Menu updateView={this.updateView} activeScreen={'UPGRADE'}/>
              </>
              <CongratsCoins visible={this.state.gratsView} onClose={this.closeCongrats}/>
            </main>
          )

        case 'WALLET':
          return (
            <main>
              <>
                <div className="placeholder__block">
                  <div className="placeholder__text">
                  {
                    (this.state.language_code === "ru") ? 
                      ("Скоро откроется")
                    :
                      ("Will be launched soon")
                  }
                  </div>
                </div>
                <Menu updateView={this.updateView} activeScreen={'WALLET'}/>
              </>
            </main>
          )
        }
      } else {
        return (
          <main>
            <>
              <LOADING userData={this.state}/>
            </>
          </main>
        )
      }
  }
}


export default App;